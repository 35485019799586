.header{
    background-color:rgb(255, 255, 255);
    height: 65px;
    width: 100%;
    padding: 0px;
    position: fixed;
    border-bottom: 1px solid rgb(202, 0, 0);
    z-index: 100;
    display: flex;
    justify-content: space-between;
}

.logoImg{
    height: 100%;
    width: 200px;
    padding: 15px 0px 0px 15px;
}

.img{
    height: 30px;
}

.faBars{
    padding: 20px;
}

.languageButton{
    width: 20%;
}


@media (min-width: 600px) {
    .header{
        background-color:chocolate;
        width: 100%;
        padding: 0px;
        position: fixed;
        border-bottom: 1px solid brown;
        z-index: 1;
    }
    
    .logoImg{
        height: 100%;
    }
}