.body {
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), 
        url(../../assets/Img_1.jpg) fixed center center;
    width: 100%;
    float: left;
    padding: 10% 5% 5% 5%;
}

h1 {
    text-align: center;
    font-weight: 900;
}