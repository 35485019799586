.body{
    /* background-image: url("../../assets/Img_1.jpg"); */
    background-color:rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    float: left;
    position: absolute;
    top: 65px;
}

