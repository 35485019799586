.navButton {
    margin-top: 0px;
    background-color:chocolate;
    height: 50px;
    width: 100%;
    text-align: center;
    position: relative;
    padding: 5px 0 5px 0;
    border-bottom: 1px solid brown;
}

.navButton:hover
{
    background-color:rgb(173, 72, 0);
}