.body {
    background-color:lightgoldenrodyellow;
    width: 100%;
    float: left;
    padding: 9% 5% 5% 5%;
}

h1 {
    text-align: center;
    font-weight: 900;
}

