.body {
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), 
        url(../../assets/Img_1.jpg) fixed center center;
    width: 100%;
    float: left;
    padding: 10% 5% 5% 5%;
}

h1, h4, h3 {
    text-align: center;
    font-weight: 600;
}

.icon {
    height: 75px;
    width: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 16px;
    border-radius: 20%;
    border: 1px solid #673ab7
}