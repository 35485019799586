.displayNone{
    display: none;
}

.sideNavBarActive{
    background-color:chocolate;
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 100;
}

.backDrop{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 43%);
    z-index: 99;
}

.languageButton{
    margin-top: 0px;
    background-color:chocolate;
    height: 66px;
    width: 100%;
    text-align: center;
    position: relative;
    padding: 5px 0 5px 0;
    border-bottom: 1px solid brown;
}

@media (min-width: 600px) {
    .sideNavBarActive{
        background-color:chocolate;
        height: 100%;
        width: 20%;
        position: fixed;

    }
  }