body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header_header__3mOev{
    background-color:rgb(255, 255, 255);
    height: 65px;
    width: 100%;
    padding: 0px;
    position: fixed;
    border-bottom: 1px solid rgb(202, 0, 0);
    z-index: 100;
    display: flex;
    justify-content: space-between;
}

.Header_logoImg__1Ys1z{
    height: 100%;
    width: 200px;
    padding: 15px 0px 0px 15px;
}

.Header_img__2xtj8{
    height: 30px;
}

.Header_faBars__1oF96{
    padding: 20px;
}

.Header_languageButton__SXsDi{
    width: 20%;
}


@media (min-width: 600px) {
    .Header_header__3mOev{
        background-color:chocolate;
        width: 100%;
        padding: 0px;
        position: fixed;
        border-bottom: 1px solid brown;
        z-index: 1;
    }
    
    .Header_logoImg__1Ys1z{
        height: 100%;
    }
}
.SideNavBar_displayNone__LpxRk{
    display: none;
}

.SideNavBar_sideNavBarActive__dvZiH{
    background-color:chocolate;
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 100;
}

.SideNavBar_backDrop__2LCjB{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 43%);
    z-index: 99;
}

.SideNavBar_languageButton__2Cacp{
    margin-top: 0px;
    background-color:chocolate;
    height: 66px;
    width: 100%;
    text-align: center;
    position: relative;
    padding: 5px 0 5px 0;
    border-bottom: 1px solid brown;
}

@media (min-width: 600px) {
    .SideNavBar_sideNavBarActive__dvZiH{
        background-color:chocolate;
        height: 100%;
        width: 20%;
        position: fixed;

    }
  }
.NavButton_navButton__2X9cb {
    margin-top: 0px;
    background-color:chocolate;
    height: 50px;
    width: 100%;
    text-align: center;
    position: relative;
    padding: 5px 0 5px 0;
    border-bottom: 1px solid brown;
}

.NavButton_navButton__2X9cb:hover
{
    background-color:rgb(173, 72, 0);
}
.Body_body__1RHRt{
    /* background-image: url("../../assets/Img_1.jpg"); */
    background-color:rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    float: left;
    position: absolute;
    top: 65px;
}


.LandingPage_body__1AgB8 {
    width: 100%;
    float: left;
    background-color: rgb(255, 255, 255);
    
}

.LandingPage_footer__3QpyI {
    width: 100%;
    float: left;
    justify-content: center;
    padding: 5%;
}
.About_body__o-WJj {
    background-color:lightgoldenrodyellow;
    width: 100%;
    float: left;
    padding: 9% 5% 5% 5%;
}

h1 {
    text-align: center;
    font-weight: 900;
}

.About_maaMadwaraniImg__3XFvk {
    width: 100%;
}

img {
    width: 100%;
}


.Schedules_body__23pSu {
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), 
        url(/static/media/Img_1.b17299b8.jpg) fixed center center;
    width: 100%;
    float: left;
    padding: 10% 5% 5% 5%;
}

h1, h4, h3 {
    text-align: center;
    font-weight: 600;
}

.Schedules_icon__2upGd {
    height: 75px;
    width: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 16px;
    border-radius: 20%;
    border: 1px solid #673ab7
}
.ContactUs_body__qYaPe {
    background-color:lightgoldenrodyellow;
    width: 100%;
    float: left;
    padding: 9% 5% 5% 5%;
}

h1 {
    text-align: center;
    font-weight: 900;
}


.JyotiKalash_body__2EVto {
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), 
        url(/static/media/Img_1.b17299b8.jpg) fixed center center;
    width: 100%;
    float: left;
    padding: 10% 5% 5% 5%;
}

h1 {
    text-align: center;
    font-weight: 900;
}
.Footer_footer__2SI18 {
    background-color:lightgoldenrodyellow;
    width: 100%;
    float: left;
}

img {
    width: 100%;
}
.Layout_body__3yU4_ {
    position: absolute;
    /* padding: 65px, 0%, 0%, 0%; */
    top: 100px;
}
